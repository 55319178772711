// // ça marchait le 22/08/2024 à 14h29
// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Container, Box, MenuItem } from '@mui/material';
// import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { getBlog, addBlog, modifyBlog } from '../redux/blogSlice';

// const BlogForm = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const location = useLocation();  // Utilisez useLocation pour accéder aux paramètres d'URL
//   const apiSource = useSelector((state) => state.api.source);
  
//   // Extraire le bloc des paramètres de l'URL
//   const queryParams = new URLSearchParams(location.search);
//   const blocFromURL = queryParams.get('bloc') || '';

//   const [blog, setBlog] = useState({
//     blogTitle: '',
//     blogDesc: '',
//     youtubeUrl: '',
//     bloc: blocFromURL,  // Pré-remplir le bloc avec la valeur passée dans l'URL
//   });

//   const blocs = ['new', 'agile', 'dam', 'erp', 'fibre', 'ged', 'git', 'ia', 'rgpd', 'sécurité', 'web2print'];

//   useEffect(() => {
//     if (id) {
//       dispatch(getBlog(id)).then((response) => {
//         setBlog(response.payload.attributes);
//       });
//     }
//   }, [id, dispatch]);

//   const handleChange = (e) => {
//     setBlog({ ...blog, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (id) {
//       await dispatch(modifyBlog({ id, blog }));
//     } else {
//       await dispatch(addBlog(blog));
//     }
//     navigate(`/blogs/${blog.bloc}`); // Redirige vers la page du bloc spécifique après la création ou la modification
//   };

//   return (
//     <Container>
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="50vh"
//       >
//         <Box
//           component="form"
//           onSubmit={handleSubmit}
//           sx={{
//             width: '100%',
//             maxWidth: '500px',
//             bgcolor: 'background.paper',
//             p: 3,
//             borderRadius: 1,
//             boxShadow: 3,
//           }}
//         >
//           <TextField
//             label="Titre"
//             name="blogTitle"
//             value={blog.blogTitle}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Description"
//             name="blogDesc"
//             value={blog.blogDesc}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="YouTube URL"
//             name="youtubeUrl"
//             value={blog.youtubeUrl}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             select
//             label="Bloc"
//             name="bloc"
//             value={blog.bloc}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           >
//             {blocs.map((bloc) => (
//               <MenuItem key={bloc} value={bloc}>
//                 {bloc.toUpperCase()}
//               </MenuItem>
//             ))}
//           </TextField>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 2 }}
//           >
//             {id ? 'Update' : 'Créer'}
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default BlogForm;

// Ce qui marchait à 17h04 le 22/08/2024
import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getBlog, addBlog, modifyBlog } from '../redux/blogSlice';

const BlogForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();  // Utilisez useLocation pour accéder aux paramètres d'URL
  const apiSource = useSelector((state) => state.api.source);
  
  // Extraire le bloc des paramètres de l'URL
  const queryParams = new URLSearchParams(location.search);
  const blocFromURL = queryParams.get('bloc') || '';

  const [blog, setBlog] = useState({
    blogTitle: '',
    blogDesc: '',
    youtubeUrl: '',
    pdfUrl: '',
    googleLink: '',
    bloc: blocFromURL,  // Pré-remplir le bloc avec la valeur passée dans l'URL
  });

  const [linkType, setLinkType] = useState(''); // Gérer le type de lien

  // const blocs = ['new', 'agile', 'dam', 'erp', 'fibre', 'ged', 'git', 'ia', 'rgpd', 'sécurité', 'web2print'];
  const blocs = ['agile', 'dam', 'erp', 'fibre', 'ged', 'git', 'ia', 'rgpd', 'sécurité', 'web2print'];

  useEffect(() => {
    if (id) {
      dispatch(getBlog(id)).then((response) => {
        setBlog(response.payload.attributes);
        if (response.payload.attributes.youtubeUrl) setLinkType('youtubeUrl');
        else if (response.payload.attributes.pdfUrl) setLinkType('pdfUrl');
        else if (response.payload.attributes.googleLink) setLinkType('googleLink');
      });
    }
  }, [id, dispatch]);

  const handleChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  const handleLinkTypeChange = (e) => {
    setLinkType(e.target.value);
    setBlog({ ...blog, youtubeUrl: '', pdfUrl: '', googleLink: '' }); // Réinitialise les autres URL lors du changement de type
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      await dispatch(modifyBlog({ id, blog }));
    } else {
      await dispatch(addBlog(blog));
    }
    navigate(`/blogs/${blog.bloc}`); // Redirige vers la page du bloc spécifique après la création ou la modification
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
        sx={{ mt: 8 }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: '500px',
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <TextField
            label="Titre"
            name="blogTitle"
            value={blog.blogTitle}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="blogDesc"
            value={blog.blogDesc}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="link-type-label">Type de lien</InputLabel>
            <Select
              labelId="link-type-label"
              id="link-type"
              value={linkType}
              label="Type de lien"
              onChange={handleLinkTypeChange}
            >
              <MenuItem value="youtubeUrl">YouTube</MenuItem>
              <MenuItem value="pdfUrl">PDF</MenuItem>
              <MenuItem value="googleLink">Lien Google</MenuItem>
            </Select>
          </FormControl>

          {linkType === 'youtubeUrl' && (
            <TextField
              label="YouTube URL"
              name="youtubeUrl"
              value={blog.youtubeUrl}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          )}
          {linkType === 'pdfUrl' && (
            <TextField
              label="PDF URL"
              name="pdfUrl"
              value={blog.pdfUrl}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          )}
          {linkType === 'googleLink' && (
            <TextField
              label="Lien Google"
              name="googleLink"
              value={blog.googleLink}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          )}

          <TextField
            select
            label="tag"
            name="bloc"
            value={blog.bloc}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {blocs.map((bloc) => (
              <MenuItem key={bloc} value={bloc}>
                {bloc.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            {id ? 'Update' : 'Créer'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogForm;

