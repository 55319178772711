// import React, { useState } from 'react';
// import { TextField, Button, Container, Box, Typography } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { loginUser } from '../services/apiAuth';
// import { login } from '../redux/authSlice';
// import Loader from './Loader';

// const Login = () => {
//   const [form, setForm] = useState({ email: '', password: '' });
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.auth.user);

//   if (user) {
//     navigate('/home'); // Redirige vers la page d'accueil si déjà connecté
//   }

//   const handleChange = (e) => {
//     setForm({ ...form, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const userData = await loginUser(form);
//       dispatch(login(userData));
//       navigate('/home'); // Redirige vers la page "/home" après la connexion réussie
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Container>
//       <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh" >
//         {loading ? (
//           <Loader />
//         ) : (
//           <Box
//             component="form"
//             onSubmit={handleSubmit}
//             sx={{ width: '100%', maxWidth: '500px', bgcolor: 'background.paper', p: 3, borderRadius: 1, boxShadow: 3 }}
//           >
//             <Typography variant="h4" textAlign="center" m={3} gutterBottom>
//               Connexion au Blog Afluens
//             </Typography>
//             {error && <Typography color="error">{error}</Typography>}
//             <TextField
//               label="Email"
//               name="email"
//               type="email"
//               value={form.email}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               label="Password"
//               name="password"
//               type="password"
//               value={form.password}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//             />
//             <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 4 }}>
//               Se connecter
//             </Button>
//           </Box>
//         )}
//       </Box>
//     </Container>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { TextField, Button, Container, Box, Typography } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { loginUser } from '../services/apiAuth';
// import { login } from '../redux/authSlice';
// import Loader from './Loader';

// const Login = () => {
//   const [form, setForm] = useState({ email: '', password: '' });
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.auth.user);

//   if (user) {
//     navigate('/home'); // Redirige vers la page d'accueil si déjà connecté
//   }

//   const handleChange = (e) => {
//     setForm({ ...form, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const userData = await loginUser(form);
//       dispatch(login(userData));
//       navigate('/home'); // Redirige vers la page "/home" après la connexion réussie
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Container>
//       <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
//         {loading ? (
//           <Loader />
//         ) : (
//           <Box
//             component="form"
//             onSubmit={handleSubmit}
//             sx={{
//               width: '100%',
//               maxWidth: '500px',
//               bgcolor: 'background.paper',
//               p: 3,
//               borderRadius: 1,
//               boxShadow: 3,
//             }}
//           >
//             <Typography
//               variant="h4"
//               textAlign="center"
//               m={3}
//               gutterBottom
//               sx={{ fontWeight: 'bold' }} // Mise en gras
//             >
//               Connexion au Blog Afluens
//             </Typography>
//             {error && <Typography color="error">{error}</Typography>}
//             <TextField
//               label="Email"
//               name="email"
//               type="email"
//               value={form.email}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               sx={{
//                 '& .MuiOutlinedInput-root': {
//                   '& fieldset': {
//                     borderColor: 'gray',
//                   },
//                   '&:hover fieldset': {
//                     borderColor: 'blue',
//                   },
//                   '&.Mui-focused fieldset': {
//                     borderColor: 'blue',
//                   },
//                 },
//                 '& .MuiInputLabel-root.Mui-focused': {
//                   color: 'blue',
//                 },
//               }}
//             />
//             <TextField
//               label="Password"
//               name="password"
//               type="password"
//               value={form.password}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               sx={{
//                 '& .MuiOutlinedInput-root': {
//                   '& fieldset': {
//                     borderColor: 'gray',
//                   },
//                   '&:hover fieldset': {
//                     borderColor: 'blue',
//                   },
//                   '&.Mui-focused fieldset': {
//                     borderColor: 'blue',
//                   },
//                 },
//                 '& .MuiInputLabel-root.Mui-focused': {
//                   color: 'blue',
//                 },
//               }}
//             />
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               fullWidth
//               sx={{
//                 mt: 4,
//                 py: 1.5,
//                 fontSize: '1rem',
//                 textTransform: 'none',
//                 '&:hover': {
//                   backgroundColor: 'blue',
//                   opacity: 0.9,
//                 },
//               }}
//             >
//               Se connecter
//             </Button>
//           </Box>
//         )}
//       </Box>
//     </Container>
//   );
// };

// export default Login;



// //  Ce qui marche correctement, j'en suis fièr 29/08/2024

// import React, { useState } from 'react';
// import { TextField, Button, Container, Box, Typography } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { loginUser } from '../services/apiAuth';
// import { login } from '../redux/authSlice';
// import Loader from './Loader';
// // Assurez-vous que le logo est correctement importé
// import afluensLogo from '../assets/logoafluens2.png'; // Remplacez par le chemin correct de votre logo

// const Login = () => {
//   const [form, setForm] = useState({ email: '', password: '' });
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.auth.user);

//   if (user) {
//     navigate('/home'); // Redirige vers la page d'accueil si déjà connecté
//   }

//   const handleChange = (e) => {
//     setForm({ ...form, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const userData = await loginUser(form);
//       dispatch(login(userData));
//       navigate('/home'); // Redirige vers la page "/home" après la connexion réussie
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Container>
//       <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
//         {loading ? (
//           <Loader />
//         ) : (
//           <Box
//             component="form"
//             onSubmit={handleSubmit}
//             sx={{
//               width: '100%',
//               maxWidth: '500px',
//               bgcolor: 'background.paper',
//               p: 3,
//               borderRadius: 1,
//               boxShadow: 3,
//             }}
//           >
//             {/* Logo Afluens Group au-dessus du titre */}
//             <Box display="flex" justifyContent="center" mb={3}>
//               <img src={afluensLogo} alt="Afluens Group" style={{ width: '150px', height: 'auto' }} />
//             </Box>

//             <Typography
//               variant="h4"
//               textAlign="center"
//               m={3}
//               gutterBottom
//               sx={{ fontWeight: 'bold' }} // Mise en gras
//             >
//               Connexion au Blog
//             </Typography>
//             {error && <Typography color="error">{error}</Typography>}
//             <TextField
//               label="Email"
//               name="email"
//               type="email"
//               value={form.email}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               sx={{
//                 '& .MuiOutlinedInput-root': {
//                   '& fieldset': {
//                     borderColor: 'gray',
//                   },
//                   '&:hover fieldset': {
//                     borderColor: 'blue',
//                   },
//                   '&.Mui-focused fieldset': {
//                     borderColor: 'blue',
//                   },
//                 },
//                 '& .MuiInputLabel-root.Mui-focused': {
//                   color: 'blue',
//                 },
//               }}
//             />
//             <TextField
//               label="Password"
//               name="password"
//               type="password"
//               value={form.password}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               sx={{
//                 '& .MuiOutlinedInput-root': {
//                   '& fieldset': {
//                     borderColor: 'gray',
//                   },
//                   '&:hover fieldset': {
//                     borderColor: 'blue',
//                   },
//                   '&.Mui-focused fieldset': {
//                     borderColor: 'blue',
//                   },
//                 },
//                 '& .MuiInputLabel-root.Mui-focused': {
//                   color: 'blue',
//                 },
//               }}
//             />
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               fullWidth
//               sx={{
//                 mt: 4,
//                 py: 1.5,
//                 fontSize: '1rem',
//                 textTransform: 'none',
//                 '&:hover': {
//                   backgroundColor: 'blue',
//                   opacity: 0.9,
//                 },
//               }}
//             >
//               Se connecter
//             </Button>
//           </Box>
//         )}
//       </Box>
//     </Container>
//   );
// };

// export default Login;



// Pour charger le site en une fois

import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/apiAuth';
import { login } from '../redux/authSlice';
import { getBlogs } from '../redux/blogSlice'; // Importez les actions nécessaires pour précharger les données
import Loader from './Loader';
import afluensLogo from '../assets/logoafluens2.png'; // Remplacez par le chemin correct de votre logo

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userData = await loginUser(form);
      dispatch(login(userData));
      // Une fois connecté, préchargez les données nécessaires
      await dispatch(getBlogs()); // Préchargez les blogs
      // Ajoutez ici d'autres appels pour précharger d'autres types de données si nécessaire
      navigate('/home'); // Redirige vers la page "/home" après le préchargement réussi
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="90vh">
        {loading ? (
          <Loader />
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: '100%',
              maxWidth: '500px',
              bgcolor: 'background.paper',
              p: 3,
              borderRadius: 1,
              boxShadow: 3,
            }}
          >
            <Box display="flex" justifyContent="center" mb={3}>
              <img src={afluensLogo} alt="Afluens Group" style={{ width: '150px', height: 'auto' }} />
            </Box>

            <Typography
              variant="h4"
              textAlign="center"
              m={3}
              gutterBottom
              sx={{ fontWeight: 'bold' }} // Mise en gras
            >
              Connexion au Blog
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            <TextField
              label="Email"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '&:hover fieldset': {
                    borderColor: 'blue',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'blue',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'blue',
                },
              }}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              value={form.password}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '&:hover fieldset': {
                    borderColor: 'blue',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'blue',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'blue',
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 4,
                py: 1.5,
                fontSize: '1rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'blue',
                  opacity: 0.9,
                },
              }}
            >
              Se connecter
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Login;
