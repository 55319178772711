// // // Avec Redux

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs, removeBlog } from '../redux/blogSlice';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);

// //   useEffect(() => {
// //     dispatch(getBlogs());
// //   }, [dispatch]);

// //   const handleDelete = (id) => {
// //     dispatch(removeBlog(id));
// //   };

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Loader />;
// //   }

// //   return (
// //     <Container>
// //       <Box display="flex" flexDirection="column" alignItems="center">
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} onDelete={handleDelete} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;


// // // HomePage.js

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import { Link } from 'react-router-dom';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs, removeBlog } from '../redux/blogSlice';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);

// //   useEffect(() => {
// //     dispatch(getBlogs());
// //   }, [dispatch]);

// //   const handleDelete = (id) => {
// //     dispatch(removeBlog(id));
// //   };

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Loader />;
// //   }

// //   return (
// //     <Container>
// //       <Box display="flex" flexDirection="column" alignItems="center">
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} onDelete={handleDelete} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //       <Button
// //         variant="contained"
// //         color="primary"
// //         component={Link}
// //         to="/create"
// //         sx={{ position: 'fixed', bottom: 16, left: 16 }}
// //       >
// //         Créer un article
// //       </Button>
// //     </Container>
// //   );
// // };

// // export default HomePage;

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);

// //   useEffect(() => {
// //     dispatch(getBlogs());
// //   }, [dispatch]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Loader />;
// //   }

// //   return (
// //     <Container>
// //       <Box display="flex" justifyContent="flex-end" mb={2}>
// //         <Button variant="contained" color="primary" component={Link} to="/edit-home">
// //           Modifier la page
// //         </Button>
// //       </Box>
// //       <Box display="flex" flexDirection="column" alignItems="center">
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;


// // src/pages/HomePage.js

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const apiSource = useSelector((state) => state.api.source);

// //   useEffect(() => {
// //     dispatch(getBlogs());
// //   }, [dispatch, apiSource]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Loader />;
// //   }

// //   return (
// //     <Container>
// //       <Box display="flex" justifyContent="flex-end" mb={2} mt={2}>
// //         <Button variant="contained" color="primary" component={Link} to="/edit-home">
// //           Modifier la page
// //         </Button>
// //       </Box>
// //       <Box display="flex" flexDirection="column" alignItems="center">
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;

// // ce qui marche le 13/08/2024

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { useParams, Link } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);

// //   const { bloc } = useParams(); // Récupère le bloc à partir de l'URL

// //   useEffect(() => {
// //     if (bloc) {
// //       dispatch(getBlogs(bloc)); // Filtre les blogs en fonction du bloc dynamique
// //     } else {
// //       dispatch(getBlogs()); // Récupère tous les blogs si aucun bloc n'est spécifié
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container>
// //       {user && user.admin && (
// //         <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000 }}>
// //           <Button variant="contained" color="primary" component={Link} to="/admin">
// //             Votre espace admin
// //           </Button>
// //         </Box>
// //       )}
// //       <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
// //         {blogs && blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;


// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link, useParams } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);
// //   const { bloc } = useParams();

// //   useEffect(() => {
// //     if (bloc === 'new') {
// //       // Charger les 10 derniers posts
// //       dispatch(getBlogs(bloc, 10));
// //     } else {
// //       // Charger les posts filtrés par bloc
// //       dispatch(getBlogs(bloc));
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container>
// //       {user && user.admin && (
// //         <>
// //           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               component={Link}
// //               to="/create"
// //               sx={{ backgroundColor: 'blue', color: 'white' }}
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //           <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000 }}>
// //             <Button variant="contained" color="primary" component={Link} to="/admin">
// //               Votre espace admin
// //             </Button>
// //           </Box>
// //         </>
// //       )}

// //       <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;

// // // ce qui marchait à 23H10

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link, useParams } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);
// //   const { bloc } = useParams();

// //   useEffect(() => {
// //     if (bloc === 'new') {
// //       // Charger les 10 derniers posts
// //       dispatch(getBlogs(bloc, 10));
// //     } else {
// //       // Charger les posts filtrés par bloc
// //       dispatch(getBlogs(bloc));
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container>
// //       {user && user.admin && (
// //         <>
// //           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               component={Link}
// //               to="/create"
// //               sx={{ backgroundColor: 'blue', color: 'white' }}
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //           <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000 }}>
// //             <Button variant="contained" color="primary" component={Link} to="/admin">
// //               Votre espace admin
// //             </Button>
// //           </Box>
// //         </>
// //       )}

// //       <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;


// // // Ce qui marche ce matin 14/08/2024 

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link, useParams } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);
// //   const { bloc } = useParams();

// //   useEffect(() => {
// //     if (bloc === 'new') {
// //       // Charger tous les posts
// //       dispatch(getBlogs());
// //     } else {
// //       // Charger les posts filtrés par bloc
// //       dispatch(getBlogs(bloc));
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container>
// //       {user && user.admin && (
// //         <>
// //           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               component={Link}
// //               to="/create"
// //               sx={{ backgroundColor: 'blue', color: 'white' }}
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //           <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000 }}>
// //             <Button variant="contained" color="primary" component={Link} to="/admin">
// //               Votre espace admin
// //             </Button>
// //           </Box>
// //         </>
// //       )}

// //       <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;

// // // ça marche aussi 24/08/2024
// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link, useParams } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);
// //   const { bloc } = useParams();  // Récupérer le bloc depuis les paramètres d'URL

// //   useEffect(() => {
// //     if (bloc === 'new') {
// //       // Charger tous les posts
// //       dispatch(getBlogs());
// //     } else {
// //       // Charger les posts filtrés par bloc
// //       dispatch(getBlogs(bloc));
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container>
// //       {user && user.admin && (
// //         <>
// //           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               component={Link}
// //               to="/create"
// //               sx={{ backgroundColor: 'blue', color: 'white' }}
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //           <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000 }}>
// //             <Button variant="contained" color="primary" component={Link} to="/admin">
// //               Votre espace admin
// //             </Button>
// //             {/* Ajout du bouton "Créer" avec le paramètre bloc */}
// //             <Button
// //               variant="contained"
// //               color="secondary"
// //               component={Link}
// //               to={`/create?bloc=${bloc}`}  // Passer le bloc sélectionné comme paramètre d'URL
// //               sx={{ marginTop: 2 }}
// //             >
// //               Créer
// //             </Button>
// //           </Box>
// //         </>
// //       )}

// //       <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;


// // // voici le code 21/08/2024

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link, useParams } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);
// //   const { bloc } = useParams();  // Récupérer le bloc depuis les paramètres d'URL

// //   useEffect(() => {
// //     if (bloc === 'new') {
// //       // Charger tous les posts
// //       dispatch(getBlogs());
// //     } else {
// //       // Charger les posts filtrés par bloc
// //       dispatch(getBlogs(bloc));
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container>
// //       {user && user.admin && (
// //         <>
// //           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               component={Link}
// //               to="/create"
// //               sx={{ backgroundColor: 'blue', color: 'white' }}
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //           <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000, display: 'flex', flexDirection: 'column', gap: 2 }}>
// //             <Button variant="contained" color="primary" component={Link} to="/admin">
// //               Votre espace admin
// //             </Button>
// //             {/* Ajout du bouton "Créer" avec le paramètre bloc */}
// //             <Button
// //               variant="contained"
// //               color="secondary"
// //               component={Link}
// //               to={`/create?bloc=${bloc}`}  // Passer le bloc sélectionné comme paramètre d'URL
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //         </>
// //       )}

// //       <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;





// // qui marche le 22/08/2024 à 23:21

// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link, useParams } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);
// //   const { bloc } = useParams();  // Récupérer le bloc depuis les paramètres d'URL

// //   useEffect(() => {
// //     if (bloc === 'new') {
// //       // Charger tous les posts
// //       dispatch(getBlogs());
// //     } else {
// //       // Charger les posts filtrés par bloc
// //       dispatch(getBlogs(bloc));
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container>
// //       {user && user.admin && (
// //         <>
// //           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               component={Link}
// //               to="/create"
// //               sx={{ backgroundColor: 'blue', color: 'white' }}
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //           <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000, display: 'flex', flexDirection: 'column', gap: 2 }}>
// //             <Button variant="contained" color="primary" component={Link} to="/admin">
// //               Votre espace admin
// //             </Button>
// //             <Button
// //               variant="contained"
// //               color="secondary"
// //               component={Link}
// //               to={`/create?bloc=${bloc}`}  // Passer le bloc sélectionné comme paramètre d'URL
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //         </>
// //       )}

// //       <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;


// // // ici j'ai baissé la taille du padding en haut des cards
// // import React, { useEffect } from 'react';
// // import { Container, Typography, Box, Button } from '@mui/material';
// // import { useDispatch, useSelector } from 'react-redux';
// // import BlogCard from '../components/BlogCard';
// // import Loader from '../components/Loader';
// // import { getBlogs } from '../redux/blogSlice';
// // import { Link, useParams } from 'react-router-dom';

// // const HomePage = () => {
// //   const dispatch = useDispatch();
// //   const { blogs, loading, error } = useSelector((state) => state.blog);
// //   const user = useSelector((state) => state.auth.user);
// //   const { bloc } = useParams();  // Récupérer le bloc depuis les paramètres d'URL

// //   useEffect(() => {
// //     if (bloc === 'new') {
// //       // Charger tous les posts
// //       dispatch(getBlogs());
// //     } else {
// //       // Charger les posts filtrés par bloc
// //       dispatch(getBlogs(bloc));
// //     }
// //   }, [dispatch, bloc]);

// //   if (loading) {
// //     return <Loader />;
// //   }

// //   if (error) {
// //     return <Typography>Error: {error}</Typography>;
// //   }

// //   return (
// //     <Container sx={{ paddingTop: '10px' }}>
// //       {user && user.admin && (
// //         <>
// //           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
// //             <Button
// //               variant="contained"
// //               color="primary"
// //               component={Link}
// //               to="/create"
// //               sx={{ backgroundColor: 'blue', color: 'white' }}
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //           <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000, display: 'flex', flexDirection: 'column', gap: 2 }}>
// //             <Button variant="contained" color="primary" component={Link} to="/admin">
// //               Votre espace admin
// //             </Button>
// //             <Button
// //               variant="contained"
// //               color="secondary"
// //               component={Link}
// //               to={`/create?bloc=${bloc}`}  // Passer le bloc sélectionné comme paramètre d'URL
// //             >
// //               Créer un post
// //             </Button>
// //           </Box>
// //         </>
// //       )}

// //       <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
// //         {blogs.length > 0 ? (
// //           blogs.map((blog) => (
// //             <Box key={blog.id} width="100%">
// //               <BlogCard blog={blog} showActions={false} />
// //             </Box>
// //           ))
// //         ) : (
// //           <Typography>No blogs available</Typography>
// //         )}
// //       </Box>
// //     </Container>
// //   );
// // };

// // export default HomePage;





// // Ce qui marche à 15h41 aujourd'hui

// import React, { useEffect } from 'react';
// import { Container, Typography, Box, Button } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import BlogCard from '../components/BlogCard';
// import Loader from '../components/Loader';
// import { getBlogs } from '../redux/blogSlice';
// import { Link, useParams } from 'react-router-dom';

// const HomePage = () => {
//   const dispatch = useDispatch();
//   const { blogs, loading, error } = useSelector((state) => state.blog);
//   const user = useSelector((state) => state.auth.user);
//   const { bloc } = useParams();  // Récupérer le bloc depuis les paramètres d'URL

//   useEffect(() => {
//     if (bloc === 'new') {
//       // Charger tous les posts
//       dispatch(getBlogs());
//     } else {
//       // Charger les posts filtrés par bloc
//       dispatch(getBlogs(bloc));
//     }
//   }, [dispatch, bloc]);

//   if (loading) {
//     return <Loader />;
//   }

//   if (error) {
//     return <Typography>Error: {error}</Typography>;
//   }

//   return (
//     <Container sx={{ paddingTop: '16px' }}>
//       {user && user.admin && (
//         <>
//           <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
//             <Button
//               variant="contained"
//               color="primary"
//               component={Link}
//               to="/create"
//               sx={{ backgroundColor: 'blue', color: 'white', padding: '4px 8px', fontSize: '0.75rem', minWidth: '100px' }}
//             >
//               Créer un post
//             </Button>
//           </Box>
//           {/* <Box sx={{ position: 'fixed', top: 70, left: 20, zIndex: 1000, display: 'flex', flexDirection: 'column', gap: 2 }}>
//             <Button
//               variant="contained"
//               color="primary"
//               component={Link}
//               to="/admin"
//               sx={{ padding: '4px 8px', fontSize: '0.75rem', minWidth: '100px' }}
//             >
//               Votre espace admin
//             </Button>
//             <Button
//               variant="contained"
//               color="secondary"
//               component={Link}
//               to={`/create?bloc=${bloc}`}  // Passer le bloc sélectionné comme paramètre d'URL
//               sx={{ padding: '4px 8px', fontSize: '0.75rem', minWidth: '100px' }}
//             >
//               Créer un post
//             </Button>
//           </Box> */}
//         </>
//       )}

//       <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
//         {blogs.length > 0 ? (
//           blogs.map((blog) => (
//             <Box key={blog.id} width="100%">
//               <BlogCard blog={blog} showActions={false} />
//             </Box>
//           ))
//         ) : (
//           <Typography>No blogs available</Typography>
//         )}
//       </Box>
//     </Container>
//   );
// };

// export default HomePage;

// Ce qui marche avec l'utilisation de ogimage.js le 29/08/2024 à 15h58

import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, Fab } from '@mui/material'; // Import Fab
import { useDispatch, useSelector } from 'react-redux';
import BlogCard from '../components/BlogCard';
import Loader from '../components/Loader';
import { getBlogs } from '../redux/blogSlice';
import { Link, useParams, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add'; // Import AddIcon

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate for redirection
  const { blogs, loading, error } = useSelector((state) => state.blog);
  const user = useSelector((state) => state.auth.user);
  const { bloc } = useParams();  // Récupérer le bloc depuis les paramètres d'URL

  useEffect(() => {
    if (bloc === 'new') {
      // Charger tous les posts
      dispatch(getBlogs());
    } else {
      // Charger les posts filtrés par bloc
      dispatch(getBlogs(bloc));
    }
  }, [dispatch, bloc]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <Container sx={{ paddingTop: '16px', position: 'relative' }}> {/* Position relative to position Fab correctly */}
      {user && user.admin && (
        <>
          <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/create"
              sx={{ backgroundColor: 'blue', color: 'white', padding: '4px 8px', fontSize: '0.75rem', minWidth: '100px' }}
            >
              Créer un post
            </Button>
          </Box>
        </>
      )}

      <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
        {blogs.length > 0 ? (
          blogs.map((blog) => (
            <Box key={blog.id} width="100%">
              <BlogCard blog={blog} showActions={false} />
            </Box>
          ))
        ) : (
          <Typography>No blogs available</Typography>
        )}
      </Box>

      {user && user.admin && (
        <Fab 
          color="primary" 
          aria-label="add" 
          sx={{ position: 'fixed', bottom: 16, right: 16 }} // Position fixed to bottom-right
          onClick={() => navigate('/create')} // Redirect to create page
        >
          <AddIcon />
        </Fab>
      )}
    </Container>
  );
};

export default HomePage;
