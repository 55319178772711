// // Avant de quitter le bureau le 13/08/2024 ça marche

// const API_URL = 'http://localhost:1337';
// // const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app'; // Remplacez par l'URL de votre serveur en production

// // Utiliser l'ID du rôle "Public" directement
// const PUBLIC_ROLE_ID = 2;

// // Fonction pour s'inscrire un nouvel utilisateur
// export const registerUser = async (user, pictureFile) => {
//   let pictureId = null;

//   // Upload de l'image si une image a été fournie
//   if (pictureFile) {
//     const formData = new FormData();
//     formData.append('files', pictureFile);

//     const uploadResponse = await fetch(`${API_URL}/api/upload`, {
//       method: 'POST',
//       body: formData,
//     });

//     const uploadData = await uploadResponse.json();

//     if (!uploadResponse.ok) {
//       throw new Error(uploadData.error.message || 'Failed to upload picture');
//     }

//     pictureId = uploadData[0].id; // ID de l'image téléchargée
//   }

//   // Création de l'utilisateur avec les données et l'image associée
//   const response = await fetch(`${API_URL}/api/users`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       username: user.username,
//       email: user.email,
//       password: user.password,
//       role: PUBLIC_ROLE_ID,
//       confirmed: true,
//       Lastname: user.Lastname,
//       Firstname: user.Firstname,
//       position: user.position,
//       agency: user.agency,
//       picture: pictureId, // Ajout de l'ID de l'image au champ picture
//     }),
//   });

//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to register user');
//   }
//   return data;
// };

// // Fonction pour connecter un utilisateur
// export const loginUser = async (user) => {
//   try {
//     const response = await fetch(`${API_URL}/api/auth/local`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         identifier: user.email,
//         password: user.password,
//       }),
//     });
//     const data = await response.json();
//     if (!response.ok) {
//       throw new Error(data.error.message || 'Failed to log in');
//     }

//     // Stockage des tokens
//     localStorage.setItem('accessToken', data.jwt);
//     localStorage.setItem('refreshToken', data.refreshToken);

//     return data;
//   } catch (error) {
//     throw error;
//   }
// };

// // Fonction pour rafraîchir le token d'accès
// export const refreshAccessToken = async () => {
//   const refreshToken = localStorage.getItem('refreshToken');
//   const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       refreshToken,
//     }),
//   });

//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to refresh token');
//   }

//   // Stocker le nouveau token d'accès
//   localStorage.setItem('accessToken', data.jwt);
// };

// // Fonction pour obtenir les détails d'un utilisateur avec son image
// export const getUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}?populate=picture`, {
//     method: 'GET',
//   });
//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to fetch user');
//   }
//   return data;
// };

// // Fonction pour obtenir la liste des utilisateurs avec leurs images
// export const getUsers = async () => {
//   const response = await fetch(`${API_URL}/api/users?populate=picture`, {
//     method: 'GET',
//   });
//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to fetch users');
//   }
//   return data;
// };

// // Fonction pour mettre à jour un utilisateur
// export const updateUser = async (id, user) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(user),
//   });
//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to update user');
//   }
//   return data;
// };

// // Fonction pour supprimer un utilisateur
// export const deleteUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'DELETE',
//   });
//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to delete user');
//   }
//   return data;
// };




// const API_URL = 'http://localhost:1337';
const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app'; // Remplacez par l'URL de votre serveur en production

// Utiliser l'ID du rôle "Public" directement
const PUBLIC_ROLE_ID = 2;

// Fonction pour s'inscrire un nouvel utilisateur
export const registerUser = async (user, pictureFile) => {
  let pictureId = null;

  // Upload de l'image si une image a été fournie
  if (pictureFile) {
    const formData = new FormData();
    formData.append('files', pictureFile);

    const uploadResponse = await fetch(`${API_URL}/api/upload`, {
      method: 'POST',
      body: formData,
    });

    const uploadData = await uploadResponse.json();

    if (!uploadResponse.ok) {
      throw new Error(uploadData.error.message || 'Failed to upload picture');
    }

    pictureId = uploadData[0].id; // ID de l'image téléchargée
  }

  // Création de l'utilisateur avec les données et l'image associée
  const response = await fetch(`${API_URL}/api/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: user.username,
      email: user.email,
      password: user.password,
      role: PUBLIC_ROLE_ID,
      confirmed: true,
      Lastname: user.Lastname,
      Firstname: user.Firstname,
      position: user.position,
      agency: user.agency,
      admin: user.admin, // Inclusion du champ admin
      picture: pictureId, // Ajout de l'ID de l'image au champ picture
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message || 'Failed to register user');
  }
  return data;
};

// Fonction pour connecter un utilisateur
export const loginUser = async (user) => {
  try {
    const response = await fetch(`${API_URL}/api/auth/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        identifier: user.email,
        password: user.password,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error.message || 'Failed to log in');
    }

    // Stockage des tokens
    localStorage.setItem('accessToken', data.jwt);
    localStorage.setItem('refreshToken', data.refreshToken);

    return data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour rafraîchir le token d'accès
export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refreshToken,
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message || 'Failed to refresh token');
  }

  // Stocker le nouveau token d'accès
  localStorage.setItem('accessToken', data.jwt);
};

// Fonction pour obtenir les détails d'un utilisateur avec son image
export const getUser = async (id) => {
  const response = await fetch(`${API_URL}/api/users/${id}?populate=picture`, {
    method: 'GET',
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message || 'Failed to fetch user');
  }
  return data;
};

// Fonction pour obtenir la liste des utilisateurs avec leurs images
export const getUsers = async () => {
  const response = await fetch(`${API_URL}/api/users?populate=picture`, {
    method: 'GET',
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message || 'Failed to fetch users');
  }
  return data;
};

// Fonction pour mettre à jour un utilisateur
export const updateUser = async (id, user) => {
  const response = await fetch(`${API_URL}/api/users/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message || 'Failed to update user');
  }
  return data;
};

// Fonction pour supprimer un utilisateur
export const deleteUser = async (id) => {
  const response = await fetch(`${API_URL}/api/users/${id}`, {
    method: 'DELETE',
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message || 'Failed to delete user');
  }
  return data;
};
