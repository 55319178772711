// // Ce qui marchait ce matin 28/08/2024

// import { createSlice } from '@reduxjs/toolkit';

// const authSlice = createSlice({
//   name: 'auth',
//   initialState: {
//     user: JSON.parse(localStorage.getItem('user')) || null, // Récupérer l'utilisateur depuis localStorage
//   },
//   reducers: {
//     login: (state, action) => {
//       state.user = action.payload.user;
//       localStorage.setItem('user', JSON.stringify(action.payload.user)); // Enregistrer dans localStorage
//     },
//     logout: (state) => {
//       state.user = null;
//       localStorage.removeItem('user'); // Supprimer de localStorage
//       localStorage.removeItem('accessToken'); // Supprimer le token d'accès
//       localStorage.removeItem('refreshToken'); // Supprimer le refreshToken
//     },
//   },
// });

// export const { login, logout } = authSlice.actions;

// export default authSlice.reducer;

// AuthSlice avec la gestion de Session expired le 28/08/2024

import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')) || null, 
    sessionExpired: false, // Add this state to track session expiration
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.sessionExpired = false;
      localStorage.setItem('user', JSON.stringify(action.payload.user));
    },
    logout: (state) => {
      state.user = null;
      state.sessionExpired = false;
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    sessionExpired: (state) => {
      state.sessionExpired = true;
      state.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
  },
});

export const { login, logout, sessionExpired } = authSlice.actions;

export default authSlice.reducer;
