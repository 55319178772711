// // routes qui marches avant de rentrer chez moi le 23/08/2024

// import React from 'react';
// import { Route, Routes, Navigate } from 'react-router-dom';
// import HomePage from '../pages/HomePage';
// import EditPage from '../components/EditPage';  // Composant EditPage pour modifier un blog spécifique
// import BlogForm from '../components/BlogForm';
// import EditHomePage from '../pages/EditHomePage';
// import SignUp from '../components/SignUp';
// import Login from '../components/Login';
// import AdminPage from '../pages/AdminPage';
// import { useSelector } from 'react-redux';

// const AppRoutes = () => {
//   const user = useSelector((state) => state.auth.user);

//   return (
//     <Routes>
//       <Route path="/" element={user ? <HomePage /> : <Navigate to="/login" />} />
//       <Route path="/home" element={user ? <HomePage /> : <Navigate to="/login" />} />
//       <Route path="/standard" element={user ? <HomePage /> : <Navigate to="/login" />} />
//       <Route path="/create" element={user ? <BlogForm /> : <Navigate to="/login" />} />
//       <Route path="/edit-home" element={user ? <EditHomePage /> : <Navigate to="/login" />} />
//       <Route path="/edit/:id" element={user ? <EditPage /> : <Navigate to="/login" />} />  {/* Utilisation de EditPage pour éditer un blog */}
//       <Route path="/blogs/:bloc" element={user ? <HomePage /> : <Navigate to="/login" />} />

//       <Route path="/signup" element={<SignUp />} />
//       <Route path="/login" element={<Login />} />
//       <Route path="/admin" element={user && user.admin ? <AdminPage /> : <Navigate to="/login" />} />
//     </Routes>
//   );
// };

// export default AppRoutes;

// // Ce qui marche à 13h49 le 28/08/2024

// import React from 'react';
// import { Route, Routes, Navigate } from 'react-router-dom';
// import HomePage from '../pages/HomePage';
// import EditPage from '../components/EditPage';
// import BlogForm from '../components/BlogForm';
// import EditHomePage from '../pages/EditHomePage';
// import SignUp from '../components/SignUp';
// import Login from '../components/Login';
// import AdminPage from '../pages/AdminPage';
// import { useSelector } from 'react-redux';

// const AppRoutes = () => {
//   const user = useSelector((state) => state.auth.user);

//   const ProtectedRoute = ({ children }) => {
//     if (!user) {
//       return <Navigate to="/login" />;
//     }
//     return children;
//   };

//   return (
//     <Routes>
//       <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
//       <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
//       <Route path="/standard" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
//       <Route path="/create" element={<ProtectedRoute><BlogForm /></ProtectedRoute>} />
//       <Route path="/edit-home" element={<ProtectedRoute><EditHomePage /></ProtectedRoute>} />
//       <Route path="/edit/:id" element={<ProtectedRoute><EditPage /></ProtectedRoute>} />
//       <Route path="/blogs/:bloc" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
//       <Route path="/signup" element={<SignUp />} />
//       <Route path="/login" element={<Login />} />
//       <Route path="/admin" element={<ProtectedRoute>{user?.admin ? <AdminPage /> : <Navigate to="/login" />}</ProtectedRoute>} />
//     </Routes>
//   );
// };

// export default AppRoutes;

// Ce qui marche avec la page session expirée

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import EditPage from '../components/EditPage';
import BlogForm from '../components/BlogForm';
import EditHomePage from '../pages/EditHomePage';
import SignUp from '../components/SignUp';
import Login from '../components/Login';
import AdminPage from '../pages/AdminPage';
import SessionExpired from '../pages/SessionExpired'; // Import the new component
import { useSelector } from 'react-redux';

const AppRoutes = () => {
  const user = useSelector((state) => state.auth.user);
  const sessionExpired = useSelector((state) => state.auth.sessionExpired);

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route path="/standard" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route path="/create" element={<ProtectedRoute><BlogForm /></ProtectedRoute>} />
      <Route path="/edit-home" element={<ProtectedRoute><EditHomePage /></ProtectedRoute>} />
      <Route path="/edit/:id" element={<ProtectedRoute><EditPage /></ProtectedRoute>} />
      <Route path="/blogs/:bloc" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<ProtectedRoute>{user?.admin ? <AdminPage /> : <Navigate to="/login" />}</ProtectedRoute>} />
      <Route path="/session-expired" element={<SessionExpired />} /> {/* Add this route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
