//  Ce qui marche en utilisant ogimage à 15h53 le 29/08/2024

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBlogs, fetchBlog, createBlog, updateBlog, deleteBlog } from '../services/api';

// Récupérer tous les blogs (avec option de filtrage par bloc et limitation)
export const getBlogs = createAsyncThunk('blogs/getBlogs', async (bloc = null, limit = null) => {
  const data = await fetchBlogs();

  let filteredBlogs = data;

  if (bloc) {
    if (bloc === 'new') {
      // Pas de filtrage, on retourne tous les posts
      filteredBlogs = data;
    } else {
      filteredBlogs = data.filter(blog => blog.attributes.bloc === bloc);
    }
  }

  return filteredBlogs;
});

// Récupérer un seul blog par ID
export const getBlog = createAsyncThunk('blogs/getBlog', async (id) => {
  const data = await fetchBlog(id);
  return data;
});

// Ajouter un nouveau blog
export const addBlog = createAsyncThunk('blogs/addBlog', async (blog) => {
  await createBlog(blog);
  return blog;
});

// Modifier un blog existant
export const modifyBlog = createAsyncThunk('blogs/modifyBlog', async ({ id, blog }) => {
  await updateBlog(id, blog);
  return { id, blog };
});

// Supprimer un blog
export const removeBlog = createAsyncThunk('blogs/removeBlog', async (id) => {
  await deleteBlog(id);
  return id;
});

const blogSlice = createSlice({
  name: 'blogs',
  initialState: {
    blogs: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getBlog.fulfilled, (state, action) => {
        state.loading = false;
        // Si tu veux stocker ou utiliser le blog récupéré, tu peux ajouter du code ici
      })
      .addCase(addBlog.fulfilled, (state, action) => {
        state.blogs.push(action.payload);
      })
      .addCase(modifyBlog.fulfilled, (state, action) => {
        const index = state.blogs.findIndex(blog => blog.id === action.payload.id);
        if (index !== -1) {
          state.blogs[index] = action.payload.blog;
        }
      })
      .addCase(removeBlog.fulfilled, (state, action) => {
        state.blogs = state.blogs.filter((blog) => blog.id !== action.payload);
      });
  },
});

export default blogSlice.reducer;

