// // src/redux/apiSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const apiSlice = createSlice({
//   name: 'api',
//   initialState: {
//     source: 'standard',
//   },
//   reducers: {
//     setApiSource: (state, action) => {
//       console.log("Setting API source to:", action.payload); // Ajouter un console.log ici
//       state.source = action.payload;
//     },
//   },
// });

// export const { setApiSource } = apiSlice.actions;

// export default apiSlice.reducer;

// import { createSlice } from '@reduxjs/toolkit';

// const apiSlice = createSlice({
//   name: 'api',
//   initialState: {
//     source: 'standard',
//   },
//   reducers: {
//     setApiSource: (state, action) => {
//       console.log("Setting API source to:", action.payload); // Ajouter un console.log ici
//       state.source = action.payload;
//     },
//   },
// });

// export const { setApiSource } = apiSlice.actions;

// export default apiSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const apiSlice = createSlice({
  name: 'api',
  initialState: {
    source: 'standard',
  },
  reducers: {
    setApiSource: (state, action) => {
      state.source = action.payload;
    },
  },
});

export const { setApiSource } = apiSlice.actions;

export default apiSlice.reducer;
