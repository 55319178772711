import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../services/apiAuth';

const SignUp = () => {
  const [form, setForm] = useState({ username: '', email: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerUser(form);
      navigate('/login');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: '100%', maxWidth: '500px', bgcolor: 'background.paper', p: 3, borderRadius: 1, boxShadow: 3 }}
        >
          <Typography variant="h4" gutterBottom>
            Veuillez créer votre compte
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          <TextField
            label="Username"
            name="username"
            value={form.username}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={form.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Créer un compte
          </Button>
          <Button 
            onClick={() => navigate('/login')}
            color="primary"
            fullWidth 
            sx={{ mt: 2 }}>
            Vous avez déjà un compte? cliquez ici
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUp;
