import React, { useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BlogCard from '../components/BlogCard';
import Loader from '../components/Loader';
import { getBlogs, removeBlog } from '../redux/blogSlice'; // Remplacer par blogSlice
import { Link } from 'react-router-dom';

const EditHomePage = () => {
  const dispatch = useDispatch();
  const { blogs, loading, error } = useSelector((state) => state.blog); // Accéder à blog dans blogSlice

  useEffect(() => {
    dispatch(getBlogs('agile')); // Passer 'agile' comme bloc pour filtrer les blogs
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(removeBlog(id)); // Utiliser removeBlog de blogSlice
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="flex-end" mb={2} mt={10}>
        <Button variant="contained" color="primary" component={Link} to="/agile/create">
          Créer article
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        {blogs && blogs.length > 0 ? (
          blogs.map((blog) => (
            <Box key={blog.id} width="100%">
              <BlogCard blog={blog} onDelete={handleDelete} showActions={true} />
            </Box>
          ))
        ) : (
          <Typography>No blogs available</Typography>
        )}
      </Box>
    </Container>
  );
};

export default EditHomePage;
