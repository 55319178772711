// import React from 'react';
// import { Container, Box, Typography, Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';

// const SessionExpired = () => {
//   const navigate = useNavigate();

//   const handleLogin = () => {
//     navigate('/login');
//   };

//   return (
//     <Container>
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="100vh"
//         flexDirection="column"
//       >
//         <Typography variant="h4" gutterBottom>
//           Session expirée
//         </Typography>
//         <Typography variant="body1" gutterBottom>
//           Veuillez vous reconnecter.
//         </Typography>
//         <Button variant="contained" color="primary" onClick={handleLogin}>
//           Se reconnecter
//         </Button>
//       </Box>
//     </Container>
//   );
// };

// export default SessionExpired;

import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SessionExpired = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    console.log("Navigating to /login");
    // navigate('/login'); // Utilisez window.location.href si navigate ne fonctionne pas
    window.location.href = '/login';
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        flexDirection="column"
      >
        <Typography variant="h4" gutterBottom>
          Session expirée
        </Typography>
        <Typography variant="body1" gutterBottom>
          Veuillez vous reconnecter.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Se reconnecter
        </Button>
      </Box>
    </Container>
  );
};

export default SessionExpired;
