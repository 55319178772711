import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';

const UserBubble = () => {
  const [showUserCard, setShowUserCard] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleUserCardToggle = () => {
    setShowUserCard(!showUserCard);
  };

  return (
    <Box className="user-bubble">
      {user && showUserCard && (
        <Card
          sx={{
            position: 'fixed',
            bottom: '10px',
            right: '10px',
            width: '200px',
            boxShadow: 3,
            zIndex: 1000,
          }}
        >
          <CardContent>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {user.Firstname} {user.Lastname}
            </Typography>
            <Typography variant="body2" color="text.primary">
              {user.position}
            </Typography>
            <Typography variant="body2" color="text.primary">
              {user.agency}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogout}
              sx={{ mt: 2, width: '100%' }}
            >
              Déconnexion
            </Button>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default UserBubble;
