// Ce qui marche ce matin 28/08/2024
import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Menu,
  MenuItem,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useMediaQuery } from '@mui/material';
import logo from '../assets/logoafluens2.png';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/authSlice';
import { getBlogs } from '../redux/blogSlice';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showUserCard, setShowUserCard] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogoClick = () => {
    dispatch(getBlogs('new')); // Récupère les posts "new"
    navigate('/home'); // Navigue vers la page d'accueil
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleUserCardToggle = () => {
    setShowUserCard((prev) => !prev);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Liste des blocs sans "new"
  const blocs = ['agile', 'dam', 'erp', 'fibre', 'ged', 'git', 'ia', 'rgpd', 'securite', 'web2print'];

  const drawer = (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{
          width: 280,
          padding: 2,
          backgroundColor: '#f4f6f8', // Couleur de fond douce
          height: '100%',
          color: '#333', // Texte avec un bon contraste
          boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.1)', // Légère ombre intérieure pour de la profondeur
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
            {/* Menu */}
          </Typography>
          <IconButton onClick={toggleDrawer(false)} sx={{ color: '#333' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {blocs.map((bloc, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={`/blogs/${bloc}`}
              selected={location.pathname === `/blogs/${bloc}`}
              sx={{
                color: location.pathname === `/blogs/${bloc}` ? '#1976d2' : '#333',
                marginBottom: 1,
                padding: '10px 20px',
                backgroundColor: location.pathname === `/blogs/${bloc}` ? '#e3f2fd' : 'transparent',
                boxShadow: location.pathname === `/blogs/${bloc}` ? 'inset 0 0 5px rgba(0, 0, 0, 0.2)' : 'none',
                '&:hover': {
                  backgroundColor: '#bbdefb',
                  color: '#1976d2',
                  boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <ListItemText
                primary={bloc.toUpperCase()}
                sx={{
                  fontSize: isMobile ? '1rem' : '1.1rem',
                  fontWeight: 'bold',
                }}
              />
            </ListItem>
          ))}
          {user && (
            <>
              {user.admin && (
                <ListItem
                  button
                  onClick={() => navigate('/admin')}
                  sx={{
                    color: '#1976d2',
                    marginBottom: 1,
                    padding: '10px 20px',
                    '&:hover': {
                      backgroundColor: '#e3f2fd',
                      color: '#1976d2',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  <ListItemText
                    primary="Mon espace admin"
                    sx={{
                      fontSize: isMobile ? '1rem' : '1.1rem',
                      fontWeight: 'bold',
                    }}
                  />
                </ListItem>
              )}
              <ListItem
                button
                onClick={handleLogout}
                sx={{
                  color: 'error.main',
                  marginBottom: 1,
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#ffcdd2',
                    color: '#d32f2f',
                    boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <LogoutIcon sx={{ marginRight: 1 }} /> {/* Icône de déconnexion */}
                <ListItemText
                  primary={`${user.Firstname} ${user.Lastname}`}
                  sx={{
                    fontSize: isMobile ? '1rem' : '1.1rem',
                    fontWeight: 'bold',
                  }}
                />
              </ListItem>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: 3 }}>
        <Toolbar>
          <Box onClick={handleLogoClick} sx={{ cursor: 'pointer', flexGrow: 1 }}>
            <img src={logo} alt="Blog Afluens" style={{ height: '40px', marginRight: '16px' }} />
          </Box>
          {user && (
            <>
              {isMobile ? (
                <>
                  <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                    <MenuIcon sx={{ color: 'black' }} />
                  </IconButton>
                  {drawer}
                </>
              ) : (
                <>
                  <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    {blocs.map((bloc) => (
                      <Button
                        key={bloc}
                        component={Link}
                        to={`/blogs/${bloc}`}
                        sx={{
                          color: location.pathname === `/blogs/${bloc}` ? 'primary.main' : 'black',
                          position: 'relative',
                          marginLeft: 1,
                          fontSize: '0.75rem',
                          '&:hover': {
                            color: 'primary.main',
                          },
                          '&::after': {
                            content: '""',
                            display: 'block',
                            height: '4px', // Augmentation de l'épaisseur du surlignement
                            backgroundColor: 'primary.main',
                            width: location.pathname === `/blogs/${bloc}` ? '100%' : '0',
                            transition: 'width 0.3s ease',
                            position: 'absolute',
                            bottom: '-4px',
                            left: 0,
                          },
                        }}
                      >
                        {bloc.toUpperCase()}
                      </Button>
                    ))}
                  </Box>
                  {user.admin && (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => navigate('/admin')}>Mon espace admin</MenuItem>
                      <MenuItem onClick={() => navigate('/create')}>Créer un post</MenuItem>
                    </Menu>
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft: 2,
                      color: 'black',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {user.Firstname} {user.Lastname}
                    <IconButton
                      color="inherit"
                      sx={{ marginLeft: 1 }}
                      onClick={handleUserCardToggle}
                    >
                      <AccountCircleIcon fontSize="large" />
                    </IconButton>
                  </Typography>
                  {showUserCard && (
                    <Card
                      sx={{
                        position: 'absolute',
                        top: '60px',
                        right: '20px',
                        width: '200px',
                        boxShadow: 3,
                        zIndex: 10,
                      }}
                    >
                      <CardContent>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {user.Firstname} {user.Lastname}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                          {user.position}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                          {user.agency}
                        </Typography>
                        {user.admin && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/admin')}
                            sx={{ mt: 2, width: '100%', fontSize: '0.70rem' }}
                          >
                            Mon espace admin
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleLogout}
                          sx={{ mt: 2, width: '100%', fontSize: '0.70rem' }}
                          startIcon={<LogoutIcon />}
                        >
                          Déconnexion
                        </Button>
                      </CardContent>
                    </Card>
                  )}
                </>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Header;
