// import { configureStore } from '@reduxjs/toolkit';
// import blogReducer from './blogSlice';

// export const store = configureStore({
//   reducer: {
//     blog: blogReducer,
//   },
// });

// export default store;
// src/redux/store.js
// src/redux/store.js
// import { configureStore } from '@reduxjs/toolkit';
// import blogReducer from './blogSlice';
// import agileReducer from './agileSlice';
// import apiReducer from './apiSlice';
// import logger from 'redux-logger';

// export const store = configureStore({
//   reducer: {
//     blog: blogReducer,
//     agile: agileReducer, // Ajoutez le reducer Agile
//     api: apiReducer,
//   },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
// });

// export default store;

// import { configureStore } from '@reduxjs/toolkit';
// import blogReducer from './blogSlice';
// import agileReducer from './agileSlice';
// import apiReducer from './apiSlice';
// import authReducer from './authSlice';
// import logger from 'redux-logger';

// export const store = configureStore({
//   reducer: {
//     blog: blogReducer,
//     agile: agileReducer,
//     api: apiReducer,
//     auth: authReducer,
//   },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
// });

// export default store;

// import { configureStore } from '@reduxjs/toolkit';
// import blogReducer from './blogSlice';
// import agileReducer from './agileSlice';
// import apiReducer from './apiSlice';
// import authReducer from './authSlice';
// import logger from 'redux-logger';
// import { refreshAccessToken } from '../services/apiAuth';

// const tokenMiddleware = (store) => (next) => async (action) => {
//   const accessToken = localStorage.getItem('accessToken');
//   if (accessToken) {
//     const expirationDate = JSON.parse(atob(accessToken.split('.')[1])).exp * 1000;
//     if (Date.now() >= expirationDate) {
//       // Le token a expiré, tentons de le rafraîchir
//       try {
//         await refreshAccessToken();
//       } catch (error) {
//         console.error("Failed to refresh access token:", error);
//         store.dispatch(logout()); // Déconnecte l'utilisateur si le refresh échoue
//       }
//     }
//   }
//   return next(action);
// };

// export const store = configureStore({
//   reducer: {
//     blog: blogReducer,
//     agile: agileReducer,
//     api: apiReducer,
//     auth: authReducer,
//   },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger, tokenMiddleware),
// });

// export default store;

// import { configureStore } from '@reduxjs/toolkit';
// import blogReducer from './blogSlice';
// import agileReducer from './agileSlice';
// import apiReducer from './apiSlice';
// import authReducer from './authSlice';
// // import postReducer from './postSlice'; // Importez le postSlice
// import tokenMiddleware from './tokenMiddleware'; // Importez le middleware
// import logger from 'redux-logger';

// export const store = configureStore({
//   reducer: {
//     blog: blogReducer,
//     agile: agileReducer,
//     api: apiReducer,
//     auth: authReducer,
//     // posts: postReducer, // Ajoutez le postReducer pour gérer les posts
//   },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger, tokenMiddleware),
// });

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import blogReducer from './blogSlice';
import apiReducer from './apiSlice';
import authReducer from './authSlice';
import tokenMiddleware from './tokenMiddleware';
import logger from 'redux-logger';

export const store = configureStore({
  reducer: {
    blog: blogReducer,
    api: apiReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger, tokenMiddleware),
});

export default store;

