// const API_URL = 'http://localhost:1337';
// // const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app';

// export const fetchBlogs = async () => {
//   const response = await fetch(`${API_URL}/api/blogs`);
//   const data = await response.json();
//   return data.data;
// };


// export const fetchBlog = async (id) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`);
//   const data = await response.json();
//   return data.data;
// };

// export const createBlog = async (blog) => {
//   await fetch(`${API_URL}/api/blogs`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ data: blog }),
//   });
// };

// export const updateBlog = async (id, blog) => {
//   await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ data: blog }),
//   });
// };

// export const deleteBlog = async (id) => {
//   await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'DELETE',
//   });
// };


// const API_URL = 'http://localhost:1337';
// // const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app';

// export const fetchBlogs = async () => {
//   const token = localStorage.getItem('accessToken');
//   const response = await fetch(`${API_URL}/api/blogs`, {
//     headers: {
//       'Authorization': `Bearer ${token}`,
//     },
//   });
//   const data = await response.json();
//   return data.data;
// };

// export const fetchBlog = async (id) => {
//   const token = localStorage.getItem('accessToken');
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     headers: {
//       'Authorization': `Bearer ${token}`,
//     },
//   });
//   const data = await response.json();
//   return data.data;
// };

// export const createBlog = async (blog) => {
//   const token = localStorage.getItem('accessToken');
//   await fetch(`${API_URL}/api/blogs`, {
//     method: 'POST',
//     headers: {
//       'Authorization': `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ data: blog }),
//   });
// };

// export const updateBlog = async (id, blog) => {
//   const token = localStorage.getItem('accessToken');
//   await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'PUT',
//     headers: {
//       'Authorization': `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ data: blog }),
//   });
// };

// export const deleteBlog = async (id) => {
//   const token = localStorage.getItem('accessToken');
//   await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'DELETE',
//     headers: {
//       'Authorization': `Bearer ${token}`,
//     },
//   });
// };


// // ce qui fonctionne le 28/08/2024 à 23:57

// const API_URL = 'http://localhost:1337';


// //const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app';

// // Fonction pour récupérer le token d'authentification
// const getAuthHeaders = () => {
//   const token = localStorage.getItem('accessToken');
//   return {
//     'Authorization': `Bearer ${token}`,
//     'Content-Type': 'application/json',
//   };
// };

// // Récupérer tous les blogs
// export const fetchBlogs = async () => {
//   const response = await fetch(`${API_URL}/api/blogs`, {
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch blogs');
//   }

//   const data = await response.json();
//   return data.data; // Retourne les données des blogs
// };

// // Récupérer un seul blog par ID
// export const fetchBlog = async (id) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch blog');
//   }

//   const data = await response.json();
//   return data.data; // Retourne les données du blog
// };

// // Créer un nouveau blog
// export const createBlog = async (blog) => {
//   const response = await fetch(`${API_URL}/api/blogs`, {
//     method: 'POST',
//     headers: getAuthHeaders(),
//     body: JSON.stringify({ data: blog }),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to create blog');
//   }
// };

// // Mettre à jour un blog par ID
// export const updateBlog = async (id, blog) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'PUT',
//     headers: getAuthHeaders(),
//     body: JSON.stringify({ data: blog }),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to update blog');
//   }
// };

// // Supprimer un blog par ID
// export const deleteBlog = async (id) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'DELETE',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to delete blog');
//   }
// };

// // Fonction pour rafraîchir le token d'accès si nécessaire
// export const refreshAccessToken = async () => {
//   const refreshToken = localStorage.getItem('refreshToken');
//   const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       refreshToken,
//     }),
//   });

//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to refresh token');
//   }

//   // Stocker le nouveau token d'accès
//   localStorage.setItem('accessToken', data.jwt);
// };

// // Fonction pour obtenir les détails d'un utilisateur avec son image
// export const getUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}?populate=picture`, {
//     method: 'GET',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch user');
//   }

//   const data = await response.json();
//   return data; // Retourne les données de l'utilisateur
// };

// // Fonction pour obtenir la liste des utilisateurs avec leurs images
// export const getUsers = async () => {
//   const response = await fetch(`${API_URL}/api/users?populate=picture`, {
//     method: 'GET',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch users');
//   }

//   const data = await response.json();
//   return data; // Retourne les données des utilisateurs
// };

// // Fonction pour mettre à jour un utilisateur par ID
// export const updateUser = async (id, user) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'PUT',
//     headers: getAuthHeaders(),
//     body: JSON.stringify(user),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to update user');
//   }

//   const data = await response.json();
//   return data; // Retourne les données mises à jour de l'utilisateur
// };

// // Fonction pour supprimer un utilisateur par ID
// export const deleteUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'DELETE',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to delete user');
//   }
// };





// // Ce qui marchait hier soir 29/08/2024


// const API_URL = 'http://localhost:1337';

// // const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app';

// // Fonction pour récupérer le token d'authentification
// const getAuthHeaders = () => {
//   const token = localStorage.getItem('accessToken');
//   return {
//     'Authorization': `Bearer ${token}`,
//     'Content-Type': 'application/json',
//   };
// };

// // Récupérer tous les blogs
// export const fetchBlogs = async () => {
//   const response = await fetch(`${API_URL}/api/blogs`, {
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch blogs');
//   }

//   const data = await response.json();
//   return data.data; // Retourne les données des blogs
// };

// // Récupérer un seul blog par ID
// export const fetchBlog = async (id) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch blog');
//   }

//   const data = await response.json();
//   return data.data; // Retourne les données du blog
// };

// // Créer un nouveau blog
// export const createBlog = async (blog) => {
//   const response = await fetch(`${API_URL}/api/blogs`, {
//     method: 'POST',
//     headers: getAuthHeaders(),
//     body: JSON.stringify({ data: blog }),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to create blog');
//   }
// };

// // Mettre à jour un blog par ID
// export const updateBlog = async (id, blog) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'PUT',
//     headers: getAuthHeaders(),
//     body: JSON.stringify({ data: blog }),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to update blog');
//   }
// };

// // Supprimer un blog par ID
// export const deleteBlog = async (id) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'DELETE',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to delete blog');
//   }
// };

// // Fonction pour rafraîchir le token d'accès si nécessaire
// export const refreshAccessToken = async () => {
//   const refreshToken = localStorage.getItem('refreshToken');
//   const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       refreshToken,
//     }),
//   });

//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to refresh token');
//   }

//   // Stocker le nouveau token d'accès
//   localStorage.setItem('accessToken', data.jwt);
// };

// // Fonction pour obtenir les détails d'un utilisateur avec son image
// export const getUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}?populate=picture`, {
//     method: 'GET',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch user');
//   }

//   const data = await response.json();
//   return data; // Retourne les données de l'utilisateur
// };

// // Fonction pour obtenir la liste des utilisateurs avec leurs images
// export const getUsers = async () => {
//   const response = await fetch(`${API_URL}/api/users?populate=picture`, {
//     method: 'GET',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch users');
//   }

//   const data = await response.json();
//   return data; // Retourne les données des utilisateurs
// };

// // Fonction pour mettre à jour un utilisateur par ID
// export const updateUser = async (id, user) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'PUT',
//     headers: getAuthHeaders(),
//     body: JSON.stringify(user),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to update user');
//   }

//   const data = await response.json();
//   return data; // Retourne les données mises à jour de l'utilisateur
// };

// // Fonction pour supprimer un utilisateur par ID
// export const deleteUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'DELETE',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to delete user');
//   }

//   const data = await response.json(); // Correction ajoutée ici
//   return data;
// };

// // Fonction pour récupérer l'image Open Graph à partir d'une URL
// export const fetchOgImage = async (url) => {
//   try {
//     const proxyUrl = "https://cors-anywhere.herokuapp.com/";
//     const response = await fetch(proxyUrl + url);
//     const data = await response.text();

//     // Parse the fetched HTML to find the og:image tag
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(data, "text/html");
//     const ogImage = doc.querySelector("meta[property='og:image']")?.getAttribute("content");

//     return ogImage;
//   } catch (error) {
//     console.error('Failed to fetch Open Graph image:', error);
//     return null;
//   }
// };




// // ça marche après la pause aussi, ne pas toucher  le 29/08/2024

// const API_URL = 'http://localhost:1337';
// //const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app';

// // Fonction pour récupérer le token d'authentification
// const getAuthHeaders = () => {
//   const token = localStorage.getItem('accessToken');
//   return {
//     'Authorization': `Bearer ${token}`,
//     'Content-Type': 'application/json',
//   };
// };

// // Récupérer tous les blogs
// export const fetchBlogs = async () => {
//   const response = await fetch(`${API_URL}/api/blogs`, {
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch blogs');
//   }

//   const data = await response.json();
//   return data.data; // Retourne les données des blogs
// };

// // Récupérer un seul blog par ID
// export const fetchBlog = async (id) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch blog');
//   }

//   const data = await response.json();
//   return data.data; // Retourne les données du blog
// };

// // Créer un nouveau blog
// export const createBlog = async (blog) => {
//   const response = await fetch(`${API_URL}/api/blogs`, {
//     method: 'POST',
//     headers: getAuthHeaders(),
//     body: JSON.stringify({ data: blog }),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to create blog');
//   }
// };

// // Mettre à jour un blog par ID
// export const updateBlog = async (id, blog) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'PUT',
//     headers: getAuthHeaders(),
//     body: JSON.stringify({ data: blog }),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to update blog');
//   }
// };

// // Supprimer un blog par ID
// export const deleteBlog = async (id) => {
//   const response = await fetch(`${API_URL}/api/blogs/${id}`, {
//     method: 'DELETE',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to delete blog');
//   }

//   const data = await response.json();
//   return data;
// };

// // Fonction pour rafraîchir le token d'accès si nécessaire
// export const refreshAccessToken = async () => {
//   const refreshToken = localStorage.getItem('refreshToken');
//   const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       refreshToken,
//     }),
//   });

//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(data.error.message || 'Failed to refresh token');
//   }

//   // Stocker le nouveau token d'accès
//   localStorage.setItem('accessToken', data.jwt);
// };

// // Fonction pour obtenir les détails d'un utilisateur avec son image
// export const getUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}?populate=picture`, {
//     method: 'GET',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch user');
//   }

//   const data = await response.json();
//   return data;
// };

// // Fonction pour obtenir la liste des utilisateurs avec leurs images
// export const getUsers = async () => {
//   const response = await fetch(`${API_URL}/api/users?populate=picture`, {
//     method: 'GET',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to fetch users');
//   }

//   const data = await response.json();
//   return data;
// };

// // Fonction pour mettre à jour un utilisateur par ID
// export const updateUser = async (id, user) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'PUT',
//     headers: getAuthHeaders(),
//     body: JSON.stringify(user),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to update user');
//   }

//   const data = await response.json();
//   return data;
// };

// // Fonction pour supprimer un utilisateur par ID
// export const deleteUser = async (id) => {
//   const response = await fetch(`${API_URL}/api/users/${id}`, {
//     method: 'DELETE',
//     headers: getAuthHeaders(),
//   });

//   if (!response.ok) {
//     throw new Error('Failed to delete user');
//   }

//   const data = await response.json(); // Correction ajoutée ici
//   return data;
// };

// export const fetchOgImage = async (url) => {
//   try {
//     const proxyUrl = "https://cors-anywhere.herokuapp.com/";
//     const response = await fetch(proxyUrl + url);
//     const data = await response.text();

//     // Parse the fetched HTML to find the og:image tag
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(data, "text/html");
//     let ogImage = doc.querySelector("meta[property='og:image']")?.getAttribute("content");

//     // Fallback to the first image in the content if og:image is not found
//     if (!ogImage) {
//       ogImage = doc.querySelector("img")?.src;
//       if (ogImage) {
//         console.warn(`Using fallback image for URL: ${url}`);
//       } else {
//         console.warn(`Aucune balise og:image trouvée pour l'URL: ${url}`);
//       }
//     } else {
//       console.log(`Image Open Graph récupérée: ${ogImage}`);
//     }

//     return ogImage;
//   } catch (error) {
//     console.error('Failed to fetch Open Graph image:', error);
//     return null;
//   }
// };







// const API_URL = 'http://localhost:1337'; // Remplacez par l'URL de votre serveur Strapi
const API_URL = 'https://blogafluens-hwiiwfkhja-od.a.run.app'; // Remplacez par l'URL de votre serveur Strapi

// Fonction pour récupérer le token d'authentification
const getAuthHeaders = () => {
  const token = localStorage.getItem('accessToken');
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

// Récupérer tous les blogs
export const fetchBlogs = async () => {
  const response = await fetch(`${API_URL}/api/blogs`, {
    headers: getAuthHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch blogs');
  }

  const data = await response.json();
  return data.data; // Retourne les données des blogs
};

// Récupérer un seul blog par ID
export const fetchBlog = async (id) => {
  const response = await fetch(`${API_URL}/api/blogs/${id}`, {
    headers: getAuthHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch blog');
  }

  const data = await response.json();
  return data.data; // Retourne les données du blog
};

// Créer un nouveau blog
export const createBlog = async (blog) => {
  const response = await fetch(`${API_URL}/api/blogs`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ data: blog }),
  });

  if (!response.ok) {
    throw new Error('Failed to create blog');
  }
};

// Mettre à jour un blog par ID
export const updateBlog = async (id, blog) => {
  const response = await fetch(`${API_URL}/api/blogs/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify({ data: blog }),
  });

  if (!response.ok) {
    throw new Error('Failed to update blog');
  }
};

// Supprimer un blog par ID
export const deleteBlog = async (id) => {
  const response = await fetch(`${API_URL}/api/blogs/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to delete blog');
  }
};

// Fonction pour rafraîchir le token d'accès si nécessaire
export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await fetch(`${API_URL}/api/auth/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refreshToken,
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error.message || 'Failed to refresh token');
  }

  // Stocker le nouveau token d'accès
  localStorage.setItem('accessToken', data.jwt);
};

// Fonction pour obtenir les détails d'un utilisateur avec son image
export const getUser = async (id) => {
  const response = await fetch(`${API_URL}/api/users/${id}?populate=picture`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user');
  }

  const data = await response.json();
  return data; // Retourne les données de l'utilisateur
};

// Fonction pour obtenir la liste des utilisateurs avec leurs images
export const getUsers = async () => {
  const response = await fetch(`${API_URL}/api/users?populate=picture`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }

  const data = await response.json();
  return data; // Retourne les données des utilisateurs
};

// Fonction pour mettre à jour un utilisateur par ID
export const updateUser = async (id, user) => {
  const response = await fetch(`${API_URL}/api/users/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error('Failed to update user');
  }

  const data = await response.json();
  return data; // Retourne les données mises à jour de l'utilisateur
};

// Fonction pour supprimer un utilisateur par ID
export const deleteUser = async (id) => {
  const response = await fetch(`${API_URL}/api/users/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to delete user');
  }

  const data = await response.json(); // Correction ajoutée ici
  return data;
};

// Fonction pour récupérer l'image Open Graph à partir d'une URL via Strapi
export const fetchOgImage = async (url) => {
  try {
    // Appelez l'API Strapi pour récupérer l'image Open Graph
    const response = await fetch(`${API_URL}/api/ogimage?url=${encodeURIComponent(url)}`);
    const data = await response.json();

    // Vérifiez si une image a été retournée
    let ogImage = data?.ogImage;

    // Mécanisme de secours : utiliser la première image trouvée si og:image n'est pas présente
    if (!ogImage) {
      console.warn(`Aucune balise og:image trouvée pour l'URL : ${url}`);
    } else {
      console.log(`Image Open Graph récupérée : ${ogImage}`);
    }

    return ogImage;
  } catch (error) {
    console.error('Échec de la récupération de l\'image Open Graph depuis Strapi:', error);
    return null;
  }
};
