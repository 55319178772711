// // // Ce qui marche le 23/08/2024 à 16:22

// import React from 'react';
// import { CssBaseline } from '@mui/material';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import Header from './components/Header';
// import AppRoutes from './routes/routes';
// import store from './redux/store';
// //import BackgroundImage from './components/BackgroundImage';
// import UserBubble from './components/UserBubble';  // Importer le composant

// function App() {
//   return (
//     <Provider store={store}>
//       <Router>
//         <CssBaseline />
//         {/* <BackgroundImage> */}
//           <Header />
//           <AppRoutes />
//           <UserBubble />  {/* Ajouter le composant ici */}
//         {/* </BackgroundImage> */}
//       </Router>
//     </Provider>
//   );
// }

// export default App;

// // Ce qui marche à 13h51 le 28/08/2024

// import React, { useEffect } from 'react';
// import { CssBaseline } from '@mui/material';
// import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
// import { Provider, useSelector } from 'react-redux';
// import Header from './components/Header';
// import AppRoutes from './routes/routes';
// import store from './redux/store';
// import UserBubble from './components/UserBubble';

// const App = () => {
//   const sessionExpired = useSelector((state) => state.auth.sessionExpired);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (sessionExpired) {
//       navigate('/session-expired');
//     }
//   }, [sessionExpired, navigate]);

//   return (
//     <Provider store={store}>
//       <Router>
//         <CssBaseline />
//         <Header />
//         <AppRoutes />
//         <UserBubble />
//       </Router>
//     </Provider>
//   );
// };

// export default App;

// import React, { useEffect } from 'react';
// import { CssBaseline } from '@mui/material';
// import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
// import { Provider, useSelector } from 'react-redux';
// import Header from './components/Header';
// import AppRoutes from './routes/routes';
// import store from './redux/store';
// import UserBubble from './components/UserBubble';

// const MainApp = () => {
//   const sessionExpired = useSelector((state) => state.auth.sessionExpired);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (sessionExpired) {
//       navigate('/session-expired');
//     }
//   }, [sessionExpired, navigate]);

//   return (
//     <>
//       <CssBaseline />
//       <Header />
//       <AppRoutes />
//       <UserBubble />
//     </>
//   );
// };

// const App = () => (
//   <Provider store={store}>
//     <Router>
//       <MainApp />
//     </Router>
//   </Provider>
// );

// export default App;


// Ce qui marche avec ogimage à 15h55 le 29/08/2024

import React, { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import Header from './components/Header';
import AppRoutes from './routes/routes';
import store from './redux/store';
import UserBubble from './components/UserBubble';

const MainApp = () => {
  const sessionExpired = useSelector((state) => state.auth.sessionExpired);
  const user = useSelector((state) => state.auth.user); // Récupérer l'utilisateur depuis l'état auth
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionExpired) {
      navigate('/session-expired');
    }
  }, [sessionExpired, navigate]);

  return (
    <>
      <CssBaseline />
      {/* Afficher le Header uniquement si l'utilisateur est connecté */}
      {user && <Header />}
      <AppRoutes />
      {user && <UserBubble />} {/* Afficher UserBubble uniquement si l'utilisateur est connecté */}
    </>
  );
};

const App = () => (
  <Provider store={store}>
    <Router>
      <MainApp />
    </Router>
  </Provider>
);

export default App;
