// import React, { useState } from 'react';
// import {
//   TextField,
//   Button,
//   Container,
//   Box,
//   Typography,
//   FormControlLabel,
//   Checkbox,
//   Grid,
//   Paper,
// } from '@mui/material';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useDropzone } from 'react-dropzone';
// import { registerUser } from '../services/apiAuth';

// const AdminPage = () => {
//   const [form, setForm] = useState({
//     username: '',
//     email: '',
//     password: '',
//     admin: false,
//     Lastname: '',
//     Firstname: '',
//     position: '',
//     agency: '',
//     picture: null,
//   });
//   const [error, setError] = useState('');
//   const navigate = useNavigate();
//   const location = useLocation();  // Récupérer l'URL actuelle pour obtenir le bloc

//   const onDrop = (acceptedFiles) => {
//     setForm({ ...form, picture: acceptedFiles[0] });
//   };

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     accept: 'image/*',
//   });

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await registerUser(form);
//       navigate('/home');
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   const bloc = new URLSearchParams(location.search).get('bloc') || 'new';  // Obtenir le bloc de l'URL

//   return (
//     <Container maxWidth="md">
//       <Paper elevation={3} sx={{ padding: 4, marginTop: 5 }}>
//         <Typography variant="h4" gutterBottom>
//           Créer un utilisateur
//         </Typography>
//         {error && <Typography color="error">{error}</Typography>}
//         <form onSubmit={handleSubmit}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Nom d'utilisateur"
//                 name="username"
//                 value={form.username}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Email"
//                 name="email"
//                 type="email"
//                 value={form.email}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Mot de passe"
//                 name="password"
//                 type="password"
//                 value={form.password}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Nom"
//                 name="Lastname"
//                 value={form.Lastname}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Prénom"
//                 name="Firstname"
//                 value={form.Firstname}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Position"
//                 name="position"
//                 value={form.position}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Agence"
//                 name="agency"
//                 value={form.agency}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Box
//                 {...getRootProps()}
//                 sx={{
//                   border: '2px dashed #cccccc',
//                   padding: '20px',
//                   textAlign: 'center',
//                   cursor: 'pointer',
//                   backgroundColor: isDragActive ? '#f0f0f0' : '#ffffff',
//                   marginTop: 2,
//                 }}
//               >
//                 <input {...getInputProps()} />
//                 {form.picture ? (
//                   <Typography variant="body1">{form.picture.name}</Typography>
//                 ) : (
//                   <Typography variant="body1">
//                     {isDragActive
//                       ? 'Déposez l\'image ici...'
//                       : 'Glissez-déposez une image ici, ou cliquez pour sélectionner un fichier'}
//                   </Typography>
//                 )}
//               </Box>
//             </Grid>
//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={form.admin}
//                     onChange={handleChange}
//                     name="admin"
//                     color="primary"
//                   />
//                 }
//                 label="Admin"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" color="primary" fullWidth>
//                 Créer un utilisateur
//               </Button>
//             </Grid>
//           </Grid>
//         </form>
//       </Paper>
//       <Box sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={() => navigate(`/create?bloc=${bloc}`)}  // Naviguer vers la page de création de post
//         >
//           Créer un post
//         </Button>
//       </Box>
//     </Container>
//   );
// };

// export default AdminPage;


import React, { useState } from 'react';
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Paper,
  IconButton,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';
import { registerUser } from '../services/apiAuth';

const AdminPage = () => {
  const [form, setForm] = useState({
    username: '',
    email: '',
    password: '',
    admin: false,
    Lastname: '',
    Firstname: '',
    position: '',
    agency: '',
    picture: null,
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation(); 

  const onDrop = (acceptedFiles) => {
    setForm({ ...form, picture: acceptedFiles[0] });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerUser(form);
      navigate('/home');
    } catch (err) {
      setError(err.message);
    }
  };

  const bloc = new URLSearchParams(location.search).get('bloc') || 'new';  

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 5, position: 'relative' }}>
        <Typography variant="h4" gutterBottom>
          Créer un utilisateur
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nom d'utilisateur"
                name="username"
                value={form.username}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={form.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mot de passe"
                name="password"
                type="password"
                value={form.password}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nom"
                name="Lastname"
                value={form.Lastname}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Prénom"
                name="Firstname"
                value={form.Firstname}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Position"
                name="position"
                value={form.position}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Agence"
                name="agency"
                value={form.agency}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed #cccccc',
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor: isDragActive ? '#f0f0f0' : '#ffffff',
                  marginTop: 2,
                  transition: 'background-color 0.3s ease',
                }}
              >
                <input {...getInputProps()} />
                {form.picture ? (
                  <Typography variant="body1">{form.picture.name}</Typography>
                ) : (
                  <Typography variant="body1">
                    {isDragActive
                      ? 'Déposez l\'image ici...'
                      : 'Glissez-déposez une image ici, ou cliquez pour sélectionner un fichier'}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.admin}
                    onChange={handleChange}
                    name="admin"
                    color="primary"
                  />
                }
                label="Admin"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Créer un utilisateur
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <IconButton
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          backgroundColor: '#1976d2',
          color: 'white',
          '&:hover': {
            backgroundColor: '#1565c0',
          },
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
        onClick={() => navigate(`/create?bloc=${bloc}`)}
      >
        <AddIcon />
      </IconButton>
    </Container>
  );
};

export default AdminPage;
