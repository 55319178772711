// // Ce qui marche le 22/08/2024

// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Container, Box } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import { getBlog, modifyBlog } from '../redux/blogSlice';

// const EditPage = () => {
//   const { id } = useParams();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const blog = useSelector((state) => state.blog.blogs.find(blog => blog.id === parseInt(id)));
//   const [formData, setFormData] = useState({
//     blogTitle: '',
//     blogDesc: '',
//     youtubeUrl: '',
//     bloc: '',
//   });

//   useEffect(() => {
//     if (blog) {
//       setFormData({
//         blogTitle: blog.attributes.blogTitle,
//         blogDesc: blog.attributes.blogDesc,
//         youtubeUrl: blog.attributes.youtubeUrl,
//         bloc: blog.attributes.bloc,
//       });
//     } else {
//       dispatch(getBlog(id)).then((response) => {
//         setFormData(response.payload.attributes);
//       });
//     }
//   }, [dispatch, id, blog]);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await dispatch(modifyBlog({ id, blog: formData }));
//     navigate(`/blogs/${formData.bloc}`);
//   };

//   return (
//     <Container>
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="50vh"
//       >
//         <Box
//           component="form"
//           onSubmit={handleSubmit}
//           sx={{
//             width: '100%',
//             maxWidth: '500px',
//             bgcolor: 'background.paper',
//             p: 3,
//             borderRadius: 1,
//             boxShadow: 3,
//           }}
//         >
//           <TextField
//             label="Titre"
//             name="blogTitle"
//             value={formData.blogTitle}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Description"
//             name="blogDesc"
//             value={formData.blogDesc}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="YouTube URL"
//             name="youtubeUrl"
//             value={formData.youtubeUrl}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Bloc"
//             name="bloc"
//             value={formData.bloc}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 2 }}
//           >
//             Modifier
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default EditPage;


// ce qui fonctionne le 22/08/2024 à 15h22

import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getBlog, modifyBlog } from '../redux/blogSlice';

const EditPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blog = useSelector((state) => state.blog.blogs.find(blog => blog.id === parseInt(id)));
  const [formData, setFormData] = useState({
    blogTitle: '',
    blogDesc: '',
    youtubeUrl: '',
    pdfUrl: '',
    googleLink: '',
    bloc: '',
  });

  const [linkType, setLinkType] = useState(''); // Gérer le type de lien

  useEffect(() => {
    if (blog) {
      setFormData({
        blogTitle: blog.attributes.blogTitle,
        blogDesc: blog.attributes.blogDesc,
        youtubeUrl: blog.attributes.youtubeUrl || '',
        pdfUrl: blog.attributes.pdfUrl || '',
        googleLink: blog.attributes.googleLink || '',
        bloc: blog.attributes.bloc,
      });

      // Déterminer le type de lien à partir des données existantes
      if (blog.attributes.youtubeUrl) setLinkType('youtubeUrl');
      else if (blog.attributes.pdfUrl) setLinkType('pdfUrl');
      else if (blog.attributes.googleLink) setLinkType('googleLink');
    } else {
      dispatch(getBlog(id)).then((response) => {
        const attributes = response.payload.attributes;
        setFormData({
          blogTitle: attributes.blogTitle,
          blogDesc: attributes.blogDesc,
          youtubeUrl: attributes.youtubeUrl || '',
          pdfUrl: attributes.pdfUrl || '',
          googleLink: attributes.googleLink || '',
          bloc: attributes.bloc,
        });

        // Déterminer le type de lien à partir des données existantes
        if (attributes.youtubeUrl) setLinkType('youtubeUrl');
        else if (attributes.pdfUrl) setLinkType('pdfUrl');
        else if (attributes.googleLink) setLinkType('googleLink');
      });
    }
  }, [dispatch, id, blog]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLinkTypeChange = (e) => {
    setLinkType(e.target.value);
    setFormData({ ...formData, youtubeUrl: '', pdfUrl: '', googleLink: '' }); // Réinitialiser les autres URL lors du changement de type
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(modifyBlog({ id, blog: formData }));
    navigate(`/blogs/${formData.bloc}`);
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            maxWidth: '500px',
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <TextField
            label="Titre"
            name="blogTitle"
            value={formData.blogTitle}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="blogDesc"
            value={formData.blogDesc}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Sélectionner le type de lien si aucun lien n'est défini */}
          {!formData.youtubeUrl && !formData.pdfUrl && !formData.googleLink && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="link-type-label">Type de lien</InputLabel>
              <Select
                labelId="link-type-label"
                id="link-type"
                value={linkType}
                label="Type de lien"
                onChange={handleLinkTypeChange}
              >
                <MenuItem value="youtubeUrl">YouTube</MenuItem>
                <MenuItem value="pdfUrl">PDF</MenuItem>
                <MenuItem value="googleLink">Lien Google</MenuItem>
              </Select>
            </FormControl>
          )}

          {/* Afficher le champ URL correspondant */}
          {linkType === 'youtubeUrl' || formData.youtubeUrl ? (
            <TextField
              label="YouTube URL"
              name="youtubeUrl"
              value={formData.youtubeUrl}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          ) : null}

          {linkType === 'pdfUrl' || formData.pdfUrl ? (
            <TextField
              label="PDF URL"
              name="pdfUrl"
              value={formData.pdfUrl}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          ) : null}

          {linkType === 'googleLink' || formData.googleLink ? (
            <TextField
              label="Lien Google"
              name="googleLink"
              value={formData.googleLink}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          ) : null}

          <TextField
            label="Bloc"
            name="bloc"
            value={formData.bloc}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Modifier
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EditPage;
