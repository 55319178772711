// // ce qui marchait ce matin du 28/08/2024

// import { logout } from './authSlice';
// import { refreshAccessToken } from '../services/apiAuth';

// const tokenMiddleware = (store) => (next) => async (action) => {
//   const accessToken = localStorage.getItem('accessToken');

//   if (accessToken) {
//     const expirationDate = JSON.parse(atob(accessToken.split('.')[1])).exp * 1000;

//     if (Date.now() >= expirationDate) {
//       try {
//         await refreshAccessToken();
//       } catch (error) {
//         console.error("Failed to refresh access token:", error);
//         store.dispatch(logout());
//         window.location.href = '/login';
//       }
//     }
//   }

//   return next(action);
// };

// export default tokenMiddleware;


// Avec Session expired ce que j'ai

import { logout } from './authSlice';
import { refreshAccessToken } from '../services/apiAuth';
import { createSlice } from '@reduxjs/toolkit';

const tokenMiddleware = (store) => (next) => async (action) => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    const expirationDate = JSON.parse(atob(accessToken.split('.')[1])).exp * 1000;

    if (Date.now() >= expirationDate) {
      try {
        await refreshAccessToken();
      } catch (error) {
        console.error("Failed to refresh access token:", error);
        store.dispatch(logout());
        store.dispatch({ type: 'auth/sessionExpired' }); // Dispatch custom action
      }
    }
  }

  return next(action);
};

export default tokenMiddleware;
